<template>
	<div class="singletreasure-page">
		<el-form inline>
			<el-form-item :label="$t('seller.screen.campaignStatus')">
				<el-select v-model="screenForm.activity_status" style="width: 100px;" @change="handleSearch">
					<el-option v-for="(item, index) in $t('seller.options.singletreasureStatus')" :key="index"
						:label="item.label" :value="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="$t('seller.screen.campaignTag')">
				<el-input v-model="screenForm.label_name" clearable>
				</el-input>
			</el-form-item>
			<el-form-item :label="$t('seller.screen.campaignId')">
				<el-input v-model="screenForm.activity_id" clearable>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="handleSearch">{{ $t('seller.actions.search') }}</el-button>
			</el-form-item>
		</el-form>
		<el-table v-loading="listLoading" :data="pageList" class="seller-table">
			<el-table-column :label="$t('seller.screen.campaignId')" prop="single_treasure_id"></el-table-column>
			<el-table-column :label="$t('seller.screen.campaignTag')" prop="label_name"></el-table-column>
			<el-table-column :label="$t('seller.screen.campaignName')" prop="name"></el-table-column>
			<el-table-column :label="$t('seller.formItem.couponType')" align="center">
				<template slot-scope="scope">
					{{ scope.row.dis_type | typeFilter($t('seller.filters.discountType')) }}
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.screen.couponLevel')" align="center">
				<template slot-scope="scope">
					{{ scope.row.dis_level | typeFilter($t('seller.filters.discountLevel')) }}
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.campaign.activityTime')" prop="limit_give_num" min-width="170px">
				<template slot-scope="scope">
					<div class="activity-time">
						<span>{{ scope.row.start_time | parseTime() }}</span>
						<span class="split">{{ $t('seller.unit.to') }}</span>
						<span>{{ scope.row.end_time | parseTime() }}</span>
					</div>
					<div>{{ $t('seller.combomeal.duration') + '：' }}{{ getTimestamp(scope) }}</div>
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.screen.createTime')">
				<template slot-scope="scope">
					{{ scope.row.create_time | parseTime() }}
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.screen.campaignStatus')">
				<template slot-scope="scope">
					{{ scope.row.activity_status | typeFilter($t('seller.filters.activityStatus')) }}
				</template>
			</el-table-column>
			<el-table-column :label="$t('seller.tableHead.handle')" min-width="150px">
				<template slot-scope="scope">
					<div class="table-actions">
						<router-link
							:to="{ name: 'singletreasureCreate', params: { id: scope.row.single_treasure_id } }"
							target="_blank">{{ [2, 3].includes(scope.row.activity_status) ? $t('seller.actions.modifyActivity') : $t('seller.actions.viewActivity') }}</router-link>
						<router-link v-if="[2, 3].includes(scope.row.activity_status)"
							:to="{ name: 'singletreasureItemDetail', params: { id: scope.row.single_treasure_id } }"
							target="_blank">{{ $t('seller.actions.setCoupon') }}</router-link>
						<router-link v-if="[2, 3].includes(scope.row.activity_status)"
							:to="{ name: 'singletreasureAddItem', params: { id: scope.row.single_treasure_id } }"
							target="_blank">{{ $t('seller.actions.addGoods') }}</router-link>
						<a v-if="scope.row.is_delete !== 1" href="javascript:void(0);"
							@click="handleDelete(scope.row.single_treasure_id)">{{ $t('seller.actions.delete') }}</a>
						<a v-if="[2, 3].includes(scope.row.activity_status)" href="javascript:void(0);"
							@click="handleSuspend(scope.row.single_treasure_id)">{{ $t('seller.actions.suspend') }}</a>
						<a v-if="[1, 4].includes(scope.row.activity_status)" href="javascript:void(0);"
							@click="handleRestartShow(scope)">{{ $t('seller.actions.restart') }}</a>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog :title="$t('seller.dialogTitle.restartActivity')" :visible.sync="dialogVisibleRestart"
			width="400px" custom-class="seller-dialog">
			<div class="dialog-restart">
				<div class="tips"><i class="el-icon-warning-outline"></i>{{ $t('seller.singletreasure.restartTips') }}</div>
				<el-form ref="restartForm" :model="restartForm" :rules="restartRule" size="small"
					label-width="100px">
					<el-form-item :label="$t('seller.screen.startTime') + '：'" prop="start_time">
						<el-date-picker v-model="restartForm.start_time" type="datetime" range-separator="-"
							:start-placeholder="$t('seller.placeholder.originDate')" value-format="timestamp"
							:end-placeholder="$t('seller.placeholder.endDate')"
							:picker-options="pickerStartOptions">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.endTime') + '：'" prop="end_time">
						<el-date-picker v-model="restartForm.end_time" type="datetime" range-separator="-"
							:start-placeholder="$t('seller.placeholder.originDate')" value-format="timestamp"
							:end-placeholder="$t('seller.placeholder.endDate')">
						</el-date-picker>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleRestart = false" size="mini">{{ $t('seller.actions.cancel') }}</el-button>
				<el-button type="primary" @click="handleRestartSubmit" size="mini">{{ $t('seller.actions.confirm') }}</el-button>
			</div>
		</el-dialog>
		<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
			@pagination="getList"></pagination>
	</div>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchTreasuresList, deleteTreasures, suspendTreasures, restartTreasures } from '@/api/seller/market'
const defaultRestart = {
	start_time: '',
	end_time: ''
}
export default {
	components: { Pagination },
	data() {
		var that = this
		var validateStartTime = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(that.$t('seller.errorMsg.campaignStartTime')))
			} else {
				callback()
			}
		}
		var validateEndTime = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(that.$t('seller.errorMsg.campaignEndTime')))
			} else if (value < Date.now()) {
				callback(new Error(that.$t('seller.errorMsg.campaignTime')))
			} else if (that.restartForm.start_time > value) {
				callback(new Error(that.$t('seller.errorMsg.campaignCurrTime')))
			} else {
				callback()
			}
		}
		return {
			screenForm: {
				activity_id: '',
				activity_status: '3',
				label_name: ''
			},
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			dialogVisibleRestart: false,
			pickerStartOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 86400000
				}
			},
			restartForm: Object.assign({}, defaultRestart),
			restartRule: {
				start_time: [
					{ required: true, validator: validateStartTime, trigger: 'change' }
				],
				end_time: [
					{ required: true, validator: validateEndTime, trigger: 'change' }
				]
			}
		}
	},
	created() {
		this.getList()
	},
	methods: {
		getList() {
			this.listLoading = true
			const params = {
				...this.screenForm,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchTreasuresList(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		handleScreenTypeChange() {
			this.pageCurr = 1
			this.getList()
		},
		getTimestamp({ row }) {
			var longTime = row.end_time - row.start_time
			var dd = parseInt(longTime / 86400)
			var longH = longTime % 86400
			var hh = parseInt(longH / 3600)
			var longM = longH % 3600
			var mm = parseInt(longM / 60)
			var ss = longM % 60
			let text = ''
			if (dd > 0) {
				text += dd + '天'
			}
			if (hh > 0) {
				text += hh + '时'
			}
			if (mm > 0) {
				text += mm + '分'
			}
			if (ss > 0) {
				text += ss + '秒'
			}
			return text
		},
		handleDelete(id) {
			this.$confirm('确定删除该活动？', '', {
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
			}).then(() => {
				deleteTreasures({ single_treasure_id: id }).then(() => {

				})
			}).catch(() => { })
		},
		handleSuspend(id) {
			this.$confirm('确定暂停该活动？', '', {
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
			}).then(() => {
				suspendTreasures({ single_treasure_id: id }).then(() => {
					this.getList()
				})
			}).catch(() => { })
		},
		handleRestartShow({ row }) {
			if (row.status === 1 && row.end_time > parseInt(Date.now() / 1000)) {
				this.$confirm('确定重启该活动？', '重启活动', {
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
					const params = {
						single_treasure_id: row.single_treasure_id,
						start_time: row.start_time,
						end_time: row.end_time
					}
					restartTreasures(params).then(() => {
						this.getList()
					})
				}).catch(() => { })
			} else {
				this.restartForm = Object.assign({}, defaultRestart)
				this.restartForm.single_treasure_id = row.single_treasure_id
				this.dialogVisibleRestart = true
				this.$nextTick(() => {
					this.$refs.restartForm.clearValidate()
				})
			}
		},
		handleRestartSubmit() {
			this.$refs.restartForm.validate((valid) => {
				if (valid) {
					const params = {
						...this.restartForm
					}
					params.start_time = parseInt(params.start_time / 1000)
					params.end_time = parseInt(params.end_time / 1000)
					restartTreasures(params).then(() => {
						this.getList()
						this.dialogVisibleRestart = false
					})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.table-actions {
	a {
		color: $--seller-primary;
		margin-right: 10px;
		display: inline-block;
	}
}

.activity-time {
	display: flex;

	span {
		width: 68px;
		flex-shrink: 0;

		&.split {
			width: auto;
			padding: 0 5px;
		}
	}
}

.dialog-restart {
	.tips {
		font-size: 14px;
		line-height: 2.4;
		color: #666;
		margin-bottom: 10px;

		[class^=el-icon-] {
			margin-right: 10px;
			color: rgb(8, 148, 236);
		}
	}
}
</style>
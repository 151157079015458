<template>
	<div class="singletreasure-page">
		<div class="page-bg">
			<div class="page-section">
				<div class="sycm-filter">
					<div class="filter-main" v-if="dateType !== 1">{{$t('sycm.common.statisticalTime')}} {{ startTime | parseTime('{y}-{m}-{d}') }} ~ {{ endTime | parseTime('{y}-{m}-{d}')}}</div>
					<div class="filter-main" v-else>{{$t('sycm.common.statisticalTime')}} {{ endTime | parseTime('{y}-{m}-{d}')}}</div>
					<ul class="filter-bar">
						<li class="bar-item">
							<div class="filter-btn" :class="{active:dateType == 1}" @click="handleDatePicker(1)">
								{{ $t('sycm.actions.day') }}
								<div style="height: 0px;width: 0;overflow:hidden">
									<el-date-picker
										v-model="dayData"
										type="date"
										ref="dayPicker"
										:placeholder="$t('sycm.placeholder.date')"
										:picker-options="pickerOptions"
										@change="changeDatePicker($event,1)">
									</el-date-picker>
								</div>
							</div>
						</li>
						<li class="bar-item">
							<div class="filter-btn" :class="{active:dateType == 3}" @click="handleChangeTime(3)">
								7{{$t('sycm.unit.day')}}</div>
						</li>
						<li class="bar-item">
							<div class="filter-btn" :class="{active:dateType == 4}" @click="handleChangeTime(4)">
								30{{$t('sycm.unit.day')}}</div>
						</li>
					</ul>
				</div>
				<div class="section-main"></div>
			</div>
			<div class="page-section">
				<div class="section-head">
					<div class="title">{{ $t('seller.combomeal.overviewTitle') }}</div>
					<div class="barbox"></div>
				</div>
				<div class="section-main">
					<div class="sycm-multiple">
						<div class="item" v-if="trendData.pay_order" :class="{active: chartType===trendData.pay_order.mark}" @click="getCombomealChart(trendData.pay_order)">
							<div class="item-main">
								<div class="name">
									<span>{{ $t('seller.combomeal.payNumberText')}}</span>
								</div>
								<div class="number">{{ trendData.pay_order.values || '-' }}</div>
							</div>
							<div class="item-box">
								<span>{{dateType | typeFilter($t('seller.filters.dateCompare'))}}</span>
								<span v-if="trendData.pay_order.rate !== 0" :class="trendData.pay_order.rate > 0?'red':'green'">{{ parseInt(trendData.pay_order.rate) }}% <i :class="trendData.pay_order.rate > 0?'el-icon-top':'el-icon-bottom'"></i></span>
								<span v-else></span>
								<span>{{ trendData.pay_order.comp_values || '-' }}</span>
							</div>
						</div>
						<div class="item" v-if="trendData.pay_buyers" :class="{active: chartType===trendData.pay_buyers.mark}" @click="getCombomealChart(trendData.pay_buyers)">
							<div class="item-main">
								<div class="name">
									<span>{{ $t('seller.combomeal.payBuyerText')}}</span>
								</div>
								<div class="number">{{ trendData.pay_buyers.values || '-' }}</div>
							</div>
							<div class="item-box">
								<span>{{dateType | typeFilter($t('seller.filters.dateCompare'))}}</span>
								<span v-if="trendData.pay_buyers.rate !== 0" :class="trendData.pay_buyers.rate > 0?'red':'green'">{{ parseInt(trendData.pay_buyers.rate) }}% <i :class="trendData.pay_buyers.rate > 0?'el-icon-top':'el-icon-bottom'"></i></span>
								<span v-else></span>
								<span>{{ trendData.pay_buyers.comp_values || '-' }}</span>
							</div>
						</div>
						<div class="item" v-if="trendData.pay_price" :class="{active: chartType===trendData.pay_price.mark}" @click="getCombomealChart(trendData.pay_price)">
							<div class="item-main">
								<div class="name">
									<span>{{ $t('seller.home.paymentAmount')}}</span>
									<el-popover placement="bottom-start" width="477" trigger="hover"
										popper-class="sycm-popover">
										<i slot="reference" class="el-icon-question"></i>
										<div class="popover-head">
											<p>{{$t('sycm.popover.title')+'“'+$t('seller.home.paymentAmount')+'”'}}</p>
											<p>{{ $t('seller.combomeal.payAmountTips1') }}</p>
										</div>
										<div class="popover-main">
											<div class="item">
												<div class="item-label">{{$t('sycm.popover.range')}}</div>
												<div class="item-block">{{ $t('seller.combomeal.splitDefine') }}</div>
											</div>
											<div class="item">
												<div class="item-label">{{ $t('seller.combomeal.payAmountTips2') }}</div>
												<div class="item-block">
													{{ $t('seller.combomeal.payAmountTips3') }}
												</div>
											</div>
										</div>
									</el-popover>
								</div>
								<div class="number">{{ trendData.pay_price.values || '-' }}</div>
							</div>
							<div class="item-box">
								<span>{{dateType | typeFilter($t('seller.filters.dateCompare'))}}</span>
								<span v-if="trendData.pay_price.rate !== 0" :class="trendData.pay_price.rate > 0?'red':'green'">{{ parseInt(trendData.pay_price.rate) }}% <i :class="trendData.pay_price.rate > 0?'el-icon-top':'el-icon-bottom'"></i></span>
								<span v-else></span>
								<span>{{ trendData.pay_price.comp_values || '-' }}</span>
							</div>
						</div>
						<div class="item" v-if="trendData.customer_price" :class="{active: chartType===trendData.customer_price.mark}" @click="getCombomealChart(trendData.customer_price)">
							<div class="item-main">
								<div class="name">
									<span>{{ $t('seller.combomeal.customerPriceText') }}</span>
								</div>
								<div class="number">{{ trendData.customer_price.values || '-' }}</div>
							</div>
							<div class="item-box">
								<span>{{dateType | typeFilter($t('seller.filters.dateCompare'))}}</span>
								<span v-if="trendData.customer_price.rate !== 0" :class="trendData.customer_price.rate > 0?'red':'green'">{{ parseInt(trendData.customer_price.rate) }}% <i :class="trendData.customer_price.rate > 0?'el-icon-top':'el-icon-bottom'"></i></span>
								<span v-else></span>
								<span>{{ trendData.customer_price.comp_values || '-' }}</span>
							</div>
						</div>
					</div>
					<div class="sycm-chart">
						<sycm-chart id="chartOption" v-if="chartOption" :key="chartKey" :x-data="chartOption.xData"
							:s-data="chartOption.sData" :max-axis="chartOption.max.toString()" :x-interval="xInterval"
							legend-left="0" height="100%" width="100%" />
					</div>
				</div>
			</div>
			<div class="page-section">
				<div class="section-head">
					<div class="title">{{ $t('seller.combomeal.activityEffect')}}</div>
					<div class="barbox">
						<el-input v-model="trendQuery.keyword" size="small" :placeholder="$t('seller.placeholder.activitySearch')" @change="getList">
							<i class="el-icon-search el-input__icon" slot="suffix" @click="getList"></i>
						</el-input>
					</div>
				</div>
				<div class="section-main">
					<el-form size="mini" style="margin: 10px 0">
						<el-form-item :label="$t('seller.screen.campaignStatus')" style="margin-bottom: 0;">
							<el-radio-group v-model="trendQuery.activity_status" @change="getList">
								<el-radio v-for="item in $t('seller.options.activityStatus')" :key="item.value" :label="item.value">{{item.label}}</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-form>
					<el-table :data="trendList" class="seller-table">
						<el-table-column :label="$t('seller.screen.campaignId')" prop="single_treasure_id" width="80px"></el-table-column>
						<el-table-column :label="$t('seller.screen.campaignName')" prop="name"></el-table-column>
						<el-table-column :label="$t('seller.tableHead.couponTime')" min-width="240px">
							<template slot-scope="scope">
								{{ scope.row.start_time | parseTime() }}~{{ scope.row.end_time | parseTime() }}
							</template>
						</el-table-column>
						<el-table-column :label="$t('seller.combomeal.yesterdayPaynum')">
							<template slot-scope="scope">{{ scope.row.pay_order || '-' }}</template>
						</el-table-column>
						<el-table-column :label="$t('seller.combomeal.yesterdayPayPrice')">
							<template slot-scope="scope">{{ scope.row.pay_price || '-' }}</template>
						</el-table-column>
						<!-- <el-table-column :label="$t('seller.tableHead.handle')"></el-table-column> -->
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sycmChart from '@/components/echart/ipollChart'
import { fetchTreasuresTrend, fetchTreasuresChart, fetchTreasuresTrendList }  from '@/api/seller/market'
export default {
	components: { sycmChart },
	data() {
		return {
			dateType: 1,
			dayData: new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()-1).getTime(),
			monthData: '',
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-1)
					return time.getTime() > Date.now() - 86400000 || time.getTime() < today.getTime() - 86400000
				},
				firstDayOfWeek: 1
			},
			startTime: new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()-1).getTime(),
			endTime: new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate()).getTime() - 1000,
			trendData: {},
			chartType: 1,
			chartKey: Math.random(),
			chartOption: null,
			xInterval: 1,
			trendQuery: {
				activity_status: 1,
				keyword: ''
			},
			trendList: []
		}
	},
	created () {
		this.getData()
		this.getList()
	},
	methods: {
		getData() { 
			const params = {
				time_from: parseInt(this.startTime / 1000),
				time_to: parseInt(this.endTime / 1000)
			}
			fetchTreasuresTrend(params).then(response => {
				const { data } = response
				this.trendData = data
				this.getCombomealChart(data.pay_order)
			})
		},
		getList() {
			const params = {
				...this.trendQuery,
				time_from: parseInt(this.startTime / 1000),
				time_to: parseInt(this.endTime / 1000)
			}
			fetchTreasuresTrendList(params).then(response => {
				const { data } = response
				this.trendList = data.list
			})
		},
		handleChangeTime(val) {
			this.dateType = val
			let today = new Date()
			switch (this.dateType) {
				case 3:
					this.startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).getTime()
					this.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime() - 1000
					break;
				case 4:
					this.startTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30).getTime()
					this.endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime() - 1000
					break;
			}
			this.getData()
		},
		handleDatePicker(type) {
			switch(type) {
				case 1:
					this.$refs.dayPicker.focus()
					break;
			}
		},
		changeDatePicker(val,type) {
			this.dateType = type
			const today = new Date(val)
			switch(type) {
				case 1:
					this.monthData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+1).getTime() - 1000
					break;
			}
			this.getData()
		},
		async getCombomealChart(item) {
			this.chartType = item.mark || ''
			const params = {
				mark: item.mark,
				time_from: parseInt(this.startTime / 1000),
				time_to: parseInt(this.endTime / 1000)
			}
			const { data } = await fetchTreasuresChart(params)
			const xData = []
			let sData = []
			const todayData = []
			const d = new Date()
			const hours = d.getHours()
			var max = 0
			data.list.forEach(item=> {
				if(item.x_values && item.x_values.indexOf('-')<0 && this.dateType !== 5) {
					xData.push(item.x_values+":00")
					if( hours >= parseInt(item.x_values)) {
						todayData.push(item.values)
					}
				} else {
					xData.push(item.x_values)
					todayData.push(item.values)
				}
				if(parseFloat(item.values) > max) {
					max = parseFloat(item.values)
				}
				if(parseFloat(item.comp_values) > max && this.dateType === 1) {
					max = parseFloat(item.comp_values)
				}
			})
			
			const nameOption = this.$t('seller.options.combomealChart')
			sData = [{
				name: nameOption[item.mark],
				data: todayData,
				type: 'line',
				showSymbol: false,
				smooth: true,
				itemStyle: {
					color: '#2062e6'
				}
			}]
			this.xInterval = 1
			this.chartKey = Math.random()
			this.chartOption = {
				max: max ? max : 4,
				xData: xData,
				sData: sData
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.singletreasure-page {

	.page-bg {
		overflow: hidden;
		background-color: #eef1f6;
	}

	.page-section {
		margin-bottom: 10px;
		background-color: #ffffff;
		box-shadow: 0 2px 8px rgb(0 0 0 / 15%);

		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;

			.title {
				font-size: 16px;
				font-weight: bold;
			}

			.barbox {
				span {
					margin-right: 10px;
				}
			}
			.el-input__icon {
				cursor: pointer;
			}
		}

		.section-main {
			padding: 0 30px 30px 30px;
		}
	}

	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;
		border-bottom: 1px solid #dbe5ed;

		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}

		.filter-bar {
			display: flex;

			.bar-item {
				margin-left: 10px;
			}

			.filter-btn {
				padding: 7px 15px;
				border-radius: 3px;
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				border: 1px solid #DCDFE6;
				background-color: #ffffff;

				&:hover {
					color: $--sycm-primary;
					border-color: $--sycm-primary;
				}

				&.active {
					color: #ffffff;
					border-color: $--sycm-primary;
					background-color: $--sycm-primary;
				}
			}
		}
	}

	.sycm-multiple {
		display: flex;
		flex-flow: wrap;
		margin-bottom: 20px;

		.item {
			width: 25%;
			flex-shrink: 0;
			padding: 16px 8px 20px;
			border-top: 4px solid transparent;
			border-bottom: 1px solid #dbe5ed;
			border-left: 1px solid #dbe5ed;
			cursor: pointer;
			font-size: 12px;
			line-height: 1.5;
			box-sizing: border-box;

			.item-main {
				.name {
					font-size: 14px;

					.el-popover__reference {
						color: #999999;
						margin-left: 4px;
						cursor: pointer;
					}
				}

				.number {
					font-size: 24px;
					font-weight: 700;
					margin-bottom: 11px;
				}
			}

			.item-box {
				color: #999999;
				max-width: 150px;
				display: flex;
				justify-content: space-between;

				.red {
					color: #fe7c24;
				}

				.green {
					color: #00ba26;
				}

				[class^=el-icon-] {
					margin-left: 6px;
					font-weight: bold;
				}
			}

			&:nth-child(4n) {
				border-right: 1px solid #dbe5ed;
			}

			&.active {
				border-top-color: $--sycm-primary;
			}
		}
	}
	.sycm-chart {
		height: 300px;
	}
}
</style>
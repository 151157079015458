<template>
	<div class="singletreasure-page">
		<el-form ref="screenForm" inline>
			<el-form-item :label="$t('seller.screen.goodsName')">
				<el-input v-model="screenForm.goods_name" clearable>
				</el-input>
			</el-form-item>
			<el-form-item :label="$t('seller.screen.goodsId')">
				<el-input v-model="screenForm.goods_ids" clearable>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="handleSearch">{{$t('seller.actions.search')}}</el-button>
			</el-form-item>
		</el-form>
		<div class="page-list">
			<table v-loading="listLoading" width="100%" class="page-table">
				<thead>
					<tr>
						<th width="40px"></th>
						<th>{{ $t('seller.tableHead.babyDescribe') }}</th>
						<th>{{ $t('seller.tableHead.unitPrice') }}</th>
						<th>{{ $t('seller.screen.campaignTag') }}</th>
						<th>{{ $t('seller.screen.campaignName') }}</th>
						<th>{{ $t('seller.tableHead.couponTime') }}</th>
						<th>{{ $t('seller.screen.couponLevel') }}</th>
						<th>{{ $t('seller.formItem.preferentialWay') }}</th>
						<th>{{ $t('seller.tableHead.handle') }}</th>
					</tr>
					<tr>
						<td colspan="9">
							<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" :label="$t('seller.checkbox.checkAll')" @change="handleCheckAll"></el-checkbox>
							<a href="javascript:void(0);" class="item-link" @click="handleBatchDelete">{{$t('seller.actions.batchWithdrawal')}}</a>
						</td>
					</tr>
				</thead>
				<tbody v-for="(item,index) in pageList" :key="index">
					<tr v-for="child in item.st_list" :key="child.single_treasure_id">
						<td :rowspan="item.st_list.length">
							<el-checkbox v-model="checkGoods" :label="item.goods_id"  @change="handleCheckGooods">{{ }}</el-checkbox>
						</td>
						<td :rowspan="item.st_list.length">
							<div class="goods-item">
								<a :href="$itemBasePath+'/item/'+item.goods_id" class="item-pic"><img :src="item.default_image" @error="goodsLoadError" alt=""></a>
								<div class="item-main">
									<div class="name"><a :href="$itemBasePath+'/item/'+item.goods_id">{{ item.goods_name }}</a></div>
									<div class="text">ID：{{ item.goods_id }}</div>
								</div>
							</div>
						</td>
						<td :rowspan="item.st_list.length" align="center">
							<div class="price">￥{{ item.price }}</div>
						</td>
						<td align="center">{{ child.name }}</td>
						<td align="center">{{ child.label_name }}</td>
						<td align="center">
							<div class="activity-time">
								<div class="time-box">
									<span>{{ child.start_time | parseTime()}}</span>
									<span class="split">{{ $t('seller.unit.to') }}</span>
									<span>{{ child.end_time | parseTime()}}</span>
								</div>
								<div class="time-tip">{{ $t('seller.combomeal.duration') }}：{{ getTimestamp(child) }}</div>
							</div>
						</td>
						<td align="center">{{ child.dis_level | typeFilter($t('seller.filters.discountLevel')) }}</td>
						<td align="center">{{ child.dis_type | typeFilter($t('seller.filters.discountType')) }}</td>
						<td align="center">
							<div class="action-link">
								<el-button v-if="child.dis_level === 2" type="text" @click="handleEditSuk(item,child)" size="mini">{{ $t('seller.actions.editCoupon') }}</el-button>
								<el-button v-else type="text" @click="handleEditGoods(item,child)" size="mini">{{ $t('seller.actions.editCoupon') }}</el-button>
								<el-button type="text" @click="handleDelete(child)" size="mini">{{ $t('seller.actions.withdrawalActivity') }}</el-button>
							</div>
						</td>
					</tr>
				</tbody>
				<tbody v-if="!pageList.length > 0">
					<tr>
						<td colspan="9" align="center" style="color: #909399;">{{ $t('seller.empty.nodata') }}</td>
					</tr>
				</tbody>
			</table>
			<!-- 商品级 -->
			<el-dialog :title="dialogTitleGoods" :visible.sync="dialogVisibleGoods" width="600px" custom-class="seller-dialog">
				<div>
					<el-form inline size="mini" v-if="postInfo.dis_info">
						<el-form-item v-if="postInfo.dis_type === 1">{{ $t('seller.singletreasure.discount1') }}<el-input v-model="postInfo.dis_info.dis_value" @input="handleGoodsDiscount" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.singletreasure.discount2') }}</el-form-item>
						<el-form-item v-else-if="postInfo.dis_type === 2" :label="$t('seller.singletreasure.salePrice')+'：'"><el-input v-model="postInfo.dis_info.dis_value" @input="handleGoodsDiscount" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input></el-form-item>
						<el-form-item v-else-if="postInfo.dis_type === 3">{{ $t('seller.singletreasure.reduce') }}<el-input v-model="postInfo.dis_info.dis_value" @input="handleGoodsDiscount" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.unit.yuan')}}</el-form-item>
						<el-form-item :label="$t('seller.screen.discountPrice')+'：'">￥{{ postInfo.dis_info.dis_price }}</el-form-item>
						<el-form-item :label="$t('seller.tableHead.perLimit')+'：'">
							<el-select v-model="postInfo.dis_info.limit" style="width: 100px;">
								<el-option :label="$t('seller.singletreasure.noLimit')" :value="0"></el-option>
								<el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="text" v-if="postInfo.erase_status !== 1" @click="handleGoodsErasure(1)">{{ $t('seller.actions.round') }}</el-button>
							<el-button type="text" v-else @click="handleGoodsErasure(0)">{{ $t('seller.actions.unround') }}</el-button>
							<el-button type="text" v-if="postInfo.erase_status !== 2" @click="handleGoodsErasure(2)">{{ $t('seller.actions.erasure') }}</el-button>
							<el-button type="text" v-else @click="handleGoodsErasure(0)">{{ $t('seller.actions.unerasure') }}</el-button>
						</el-form-item>
					</el-form>
					<div>
						<p v-if="errorGoods[postInfo.single_treasure_goods_id] === 1" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText1') }}</p>
						<p v-else-if="errorGoods[postInfo.single_treasure_goods_id] === 2" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText2') }}</p>
						<p v-else-if="errorGoods[postInfo.single_treasure_goods_id] === 3" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText3') }}{{ scope.row.origin_price }}{{ $t('seller.singletreasure.errorText4') }}</p>
						<p v-else-if="errorGoods[postInfo.single_treasure_goods_id] === 4" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText5') }}</p>
						<p v-else-if="errorGoods[postInfo.single_treasure_goods_id] === 5" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText6') }}</p>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogVisibleGoods = false" size="mini">{{ $t('seller.actions.cancel') }}</el-button>
					<el-button type="primary" @click="handleConfirmGoods" size="mini">{{ $t('seller.actions.confirm') }}</el-button>
				</div>
			</el-dialog>
			<!-- SKU级 -->
			<el-dialog :title="dialogTitleSku" :visible.sync="dialogVisibleSku" width="1200px" custom-class="seller-dialog">
				<el-scrollbar v-if="postInfo" class="dialog-sku">
					<div class="dialog-head">
						<div v-if="postInfo.dis_type === 1">
							{{ $t('seller.singletreasure.discount1') }}<el-input v-model="dialogDiscount" size="mini" @input="handleSkuDiscountAll" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.singletreasure.discount2') }}
						</div>
						<div v-else-if="postInfo.dis_type === 2">
							{{ $t('seller.singletreasure.reduce') }}<el-input v-model="dialogDiscount" size="mini" @input="handleSkuDiscountAll" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.unit.yuan')}}
						</div>
						<div v-else-if="postInfo.dis_type === 3">
							{{ $t('seller.singletreasure.salePrice') +'：' }}<el-input v-model="dialogDiscount" size="mini" @input="handleSkuDiscountAll" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>
						</div>
						<div>
							<el-button type="text" v-if="postInfo.erase_status !== 1" @click="handleDialogErasure(1)">{{ $t('seller.actions.round') }}</el-button>
							<el-button type="text" v-else @click="handleDialogErasure(0)">{{ $t('seller.actions.unround') }}</el-button>
							<el-button type="text" v-if="postInfo.erase_status !== 2" @click="handleDialogErasure(2)">{{ $t('seller.actions.erasure') }}</el-button>
							<el-button type="text" v-else @click="handleDialogErasure(0)">{{ $t('seller.actions.unerasure') }}</el-button>
						</div>
					</div>
					<el-table :data="postInfo.spec_list" border>
						<el-table-column label="SKU_ID" prop="spec_id"></el-table-column>
						<el-table-column label="SKU">
							<template slot-scope="scope">
								<span style="margin-right: 10px;">{{ scope.row.spec_1 }}</span>
								<span>{{ scope.row.spec_2 }}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('seller.tableHead.price')" prop="origin_price"></el-table-column>
						<el-table-column :label="$t('seller.tableHead.discount')">
							<template slot-scope="scope">
								<p v-if="postInfo.dis_type === 1">
									{{ $t('seller.singletreasure.discount1') }}<el-input v-model="scope.row.dis_value" size="mini" @input="handleSkuDiscount($event, scope.row.spec_id)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{ $t('seller.singletreasure.discount2') }}
								</p>
								<p v-else-if="postInfo.dis_type === 2">
									{{ $t('seller.singletreasure.reduce') }}<el-input v-model="scope.row.dis_value" size="mini" @input="handleSkuDiscount($event, scope.row.spec_id)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin: 0 5px;"></el-input>{{$t('seller.unit.yuan')}}
								</p>
								<p v-else-if="postInfo.dis_type === 3">
									{{ $t('seller.singletreasure.salePrice') +'：' }}<el-input v-model="scope.row.dis_value" size="mini" @input="handleSkuDiscount($event, scope.row.spec_id)" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 68px;margin-left: 5px;"></el-input>
								</p>
								<p v-if="errorGoods[scope.row.spec_id] === 1" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText1') }}</p>
								<p v-else-if="errorGoods[scope.row.spec_id] === 2" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText2') }}</p>
								<p v-else-if="errorGoods[scope.row.spec_id] === 3" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText3') }}{{ scope.row.origin_price }}{{ $t('seller.singletreasure.errorText4') }}</p>
								<p v-else-if="errorGoods[scope.row.spec_id] === 4" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText5') }}</p>
								<p v-else-if="errorGoods[scope.row.spec_id] === 5" class="table-error"><i class="el-icon-circle-close"></i>{{ $t('seller.singletreasure.errorText6') }}</p>
							</template>
						</el-table-column>
						<el-table-column :label="$t('seller.tableHead.discountPrice')" prop="dis_price"></el-table-column>
					</el-table>
				</el-scrollbar>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogVisibleSku = false" size="mini">{{ $t('seller.actions.cancel') }}</el-button>
					<el-button type="primary" @click="handleConfirmSku" size="mini">{{ $t('seller.actions.confirm') }}</el-button>
				</div>
			</el-dialog>
		</div>
		<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
			@pagination="getList"></pagination>
	</div>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchTreasuresItems, updateTreasuresGoods, deleteTreasuresGoods } from '@/api/seller/market'
export default {
	components: { Pagination },
	props: ['type'],
	data() {
		return {
			screenForm: {
				goods_name: '',
				goods_ids: ''
			},
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			postInfo: {},
			errorGoods: {},
			dialogVisibleGoods: false,
			dialogTitleGoods: '',
			dialogVisibleSku: false,
			dialogTitleSku: '',
			dialogDiscount: '',
			checkAll: false,
			checkGoods: [],
			isIndeterminate: false
		}
	},
	created () {
		this.getList()
	},
	methods: {
		goodsLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		getList() {
			this.listLoading = true
			let param = {
				...this.screenForm,
				dis_level: this.type,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchTreasuresItems(param).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		getTimestamp(row) {
			var longTime = row.end_time - row.start_time
			var dd = parseInt(longTime / 86400)
			var longH = longTime % 86400
			var hh = parseInt(longH / 3600)
			var longM = longH % 3600
			var mm = parseInt(longM / 60)
			var ss = longM % 60
			let text = ''
			if(dd > 0) {
				text += dd + '天'
			}
			if(hh > 0) {
				text += hh + '时'
			}
			if(mm > 0) {
				text += mm + '分'
			}
			if(ss > 0) {
				text += ss + '秒'
			}
			return text
		},
		handleEditGoods(goods,activity) {
            const option = this.$t('seller.filters.discountType')
            this.dialogTitleGoods = '宝贝' + option[activity.dis_type] + '设置'
			this.postInfo = Object.assign({}, activity)
			this.errorGoods = {}
			this.dialogVisibleGoods = true
		},
        handleGoodsDiscount(val) {
			this.uploadGoodsPrice()
        },
		handleGoodsErasure(type) {
			this.postInfo.erase_status = type
			this.uploadGoodsPrice()
		},
		uploadGoodsPrice() {
            var goods = Object.assign({}, this.postInfo.dis_info)
            this.errorGoods[this.postInfo.single_treasure_goods_id] = 0
            switch (this.postInfo.dis_type) {
                case 1:
                    goods.dis_price = goods.origin_price * goods.dis_value / 10
                    if (goods.dis_value * 1 > 9.99 || goods.dis_value * 1 < 0.01) {
                        this.errorGoods[this.postInfo.single_treasure_goods_id] = 1
                    } else if (goods.dis_value * 1 < 7) {
                        this.errorGoods[this.postInfo.single_treasure_goods_id] = 4
                    }
                    break;
                case 2:
                    goods.dis_price = goods.origin_price - goods.dis_value * 1
                    if (goods.dis_value * 1 > goods.origin_price) {
                        this.errorGoods[this.postInfo.single_treasure_goods_id] = 2
                    } else if (goods.dis_price / goods.origin_price < 0.7) {
                        this.errorGoods[this.postInfo.single_treasure_goods_id] = 4
                    }
                    break;
                case 3:
                    goods.dis_price = goods.dis_value * 1
                    if (goods.dis_value * 1 > goods.origin_price || goods.dis_value * 1 < 0.01) {
                        this.errorGoods[this.postInfo.single_treasure_goods_id] = 3
                    } else if (goods.dis_price / goods.origin_price < 0.7) {
                        this.errorGoods[this.postInfo.single_treasure_goods_id] = 4
                    }
                    break;
            }
            if (this.postInfo.erase_status === 1) { // 取整
                goods.dis_price = parseInt(goods.dis_price)
            } else if (this.postInfo.erase_status === 2) { // 抹分
                goods.dis_price = parseFloat(goods.dis_price).toFixed(1)
            } else {
                goods.dis_price = parseFloat(goods.dis_price).toFixed(2)
            }
            this.postInfo.dis_info = Object.assign({}, goods)
		},
		handleConfirmGoods() {
			var flag = true
			if(this.errorGoods[this.postInfo.single_treasure_goods_id] > 0) {
				flag = false
			}
			if(!this.postInfo.dis_info.dis_value) {
				this.errorGoods[this.postInfo.single_treasure_goods_id] = 5
				flag = false
			}
			if(flag) {
				const params = {
					...this.postInfo.dis_info
				}
				params.spec_list = JSON.stringify(params.spec_list)
				updateTreasuresGoods(params).then(() => {
					this.dialogVisibleGoods = false
					this.getList()
				})
			}
		},
		handleEditSuk(goods,activity) {
            const option = this.$t('seller.filters.discountType')
            this.dialogTitleSku = 'SKU' + option[activity.dis_type] + '设置'
			this.postInfo = Object.assign({}, activity)
			this.errorGoods = {}
			this.dialogDiscount = ''
			this.dialogVisibleSku = true
		},
		handleSkuDiscount(val, specId) {
            this.postInfo.spec_list.forEach(item => {
                if (item.spec_id === specId) {
                    this.errorGoods[specId] = 0
                    switch (this.postInfo.dis_type) {
                        case 1:
                            item.dis_price = item.origin_price * item.dis_value / 10
                            if (item.dis_value * 1 > 9.99 || item.dis_value * 1 < 0.01) {
                                this.errorGoods[item.spec_id] = 1
                            } else if (item.dis_value * 1 < 7) {
                                this.errorGoods[item.spec_id] = 4
                            }
                            break;
                        case 2:
                            item.dis_price = item.origin_price - item.dis_value * 1
                            if (item.dis_value * 1 > item.origin_price) {
                                this.errorGoods[item.spec_id] = 2
                            } else if (item.dis_price / item.origin_price < 0.7) {
                                this.errorGoods[item.spec_id] = 4
                            }
                            break;
                        case 3:
                            item.dis_price = item.dis_value * 1
                            if (item.dis_value * 1 > item.origin_price || item.dis_value * 1 < 0.01) {
                                this.errorGoods[item.spec_id] = 3
                            } else if (item.dis_price / item.origin_price < 0.7) {
                                this.errorGoods[item.spec_id] = 4
                            }
                            break;
                    }
                    if (this.postInfo.erase_status === 1) { // 取整
                        item.dis_price = parseInt(item.dis_price)
                    } else if (this.postInfo.erase_status === 2) { // 抹分
                        item.dis_price = parseFloat(item.dis_price).toFixed(1)
                    } else {
                        item.dis_price = parseFloat(item.dis_price).toFixed(2)
                    }
                }
            })
		},
		handleSkuDiscountAll() {
			this.uploadSkuAllPrice(1)
		},
        handleDialogErasure(type) {
            this.postInfo.erase_status = type
			this.$forceUpdate()
            this.uploadSkuAllPrice(0)
        },
		uploadSkuAllPrice(type) {
			this.postInfo.spec_list.forEach(item => {
				this.errorGoods[item.spec_id] = 0
				if (this.dialogDiscount && type === 1) {
                    item.dis_value = this.dialogDiscount
                }
                switch (this.postInfo.dis_type) {
                    case 1:
                        item.dis_price = item.origin_price * item.dis_value / 10
                        if (item.dis_value * 1 > 9.99 || item.dis_value * 1 < 0.01) {
                            this.errorGoods[item.spec_id] = 1
                        } else if (item.dis_value * 1 < 7) {
                            this.errorGoods[item.spec_id] = 4
                        }
                        break;
                    case 2:
                        item.dis_price = item.origin_price - item.dis_value * 1
                        if (item.dis_value * 1 > item.origin_price) {
                            this.errorGoods[item.spec_id] = 2
                        } else if (item.dis_price / item.origin_price < 0.7) {
                            this.errorGoods[item.spec_id] = 4
                        }
                        break;
                    case 3:
                        item.dis_price = item.dis_value * 1
                        if (item.dis_value * 1 > item.origin_price || item.dis_value * 1 < 0.01) {
                            this.errorGoods[item.spec_id] = 3
                        } else if (item.dis_price / item.origin_price < 0.7) {
                            this.errorGoods[item.spec_id] = 4
                        }
                        break;
                }
                if (this.postInfo.erase_status === 1) { // 取整
                    item.dis_price = parseInt(item.dis_price)
                } else if (this.postInfo.erase_status === 2) { // 抹分
                    item.dis_price = parseFloat(item.dis_price).toFixed(1)
                } else {
                    item.dis_price = parseFloat(item.dis_price).toFixed(2)
                }
			})
		},
		handleConfirmSku() {
            var flag = true
            this.postInfo.spec_list.forEach(item => {
                if(this.errorGoods[item.spec_id] > 0) {
                    flag = false
                }
                if(!item.dis_value) {
                    this.errorGoods[item.spec_id] = 5
                    flag = false
                }
            })
            if(flag) {
				const params = {
					...this.postInfo
				}
				params.spec_list =JSON.stringify(params.spec_list)
				updateTreasuresGoods(params).then(() => {
					this.dialogVisibleSku = false
					this.getList()
				})
            }
		},
		handleDelete(activity) {
            this.$confirm(this.$t('seller.confirm.withdrawalActivityText'), this.$t('seller.actions.withdrawalActivity'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
				deleteTreasuresGoods({ single_treasure_goods_id: activity.single_treasure_goods_id}).then(() => {
					this.getList()
				})
            }).catch(() => { })
		},
		handleCheckAll(val) {
			var checkId = []
			if(val) {
				this.pageList.forEach(item => {
					checkId.push(item.goods_id)
				})
			}
			this.checkGoods = checkId
			this.isIndeterminate = false
		},
		handleCheckGooods(val) {
			let checkedCount = this.checkGoods.length
			this.checkAll = checkedCount === this.pageList.length
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.pageList.length
		},
		handleBatchDelete() {
			if(!this.checkGoods.length  > 0) {
				this.$message.error(this.$t('seller.errorMsg.ckeckItem'))
				return false
			}
            this.$confirm(this.$t('seller.confirm.batchWithdrawalActivity'), this.$t('seller.confirm.batchWithdrawalTitle'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
				var ids = []
				this.pageList.forEach(item => {
					if(this.checkGoods.includes(item.goods_id)) {
						item.st_list.forEach(child => {
							ids.push(child.single_treasure_goods_id)
						})
					}
				})
				deleteTreasuresGoods({ single_treasure_goods_id: ids.join(',')}).then(() => {
					this.getList()
				})
            }).catch(() => { })
		}
	}
}
</script>

<style lang="scss" scoped>
.singletreasure-page {
	background-color: #FFFFFF;
	.page-table {
		font-size: 14px;
		th,td {
			padding: 12px 10px;
			line-height: 23px;
		}
		thead {
			th {
				color: #909399;
				background-color: $--seller-thead-background-1;
				&:first-child {
					border-top-left-radius: 10px;
				}
				&:last-child {
					border-top-right-radius: 10px;
				}
			}
		}
		tr {
			border-bottom: 1px solid #EBEEF5;
		}
		.price {
			color: #f23f40;
		}
	}
	.item-link {
		margin-left: 10px;
		color: $--seller-primary;
	}
	.goods-item {
		display: flex;
		min-width: 200px;
		.item-pic {
			flex-shrink: 0;
			width: 52px;
			height: 52px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.item-main {
			flex: 1;
			width: 0;
			margin-left: 10px;
			.name {
				min-height: 32px;
				font-size: 12px;
				line-height: 16px;
				margin-bottom: 5px;
				a {
					color: #5584ff;
				}
			}
			.text {
				font-size: 12px;
				line-height: 20px;
			}
		}
	}
	
	.activity-time {
		display: inline-block;
		.time-box {
			display: flex;
			text-align: left;
			span {
				width: 68px;
				flex-shrink: 0;
				&.split {
					width: auto;
					padding: 0 5px;
				}
			}

		}
	}
}
.dialog-sku {
	max-height: 600px;
    .dialog-head {
        display: flex;
        margin-bottom: 12px;
        justify-content: space-between;
    }
}
.table-error {
    margin-top: 5px;
    display: inline-block;
    padding: 5px;
    line-height: 1.5;
    background-color: #fae7e7;

    [class^=el-icon-] {
        color: #ff3333;
        margin-right: 5px;
    }
}
</style>
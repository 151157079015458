<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{$t('seller.breadcrumb.seller')}}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'marketingtools' }">{{$t('seller.breadcrumb.marketPlatform')}}
				</el-breadcrumb-item>
				<el-breadcrumb-item>{{$t('seller.breadcrumb.singletreasure')}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="page-tip">
				<div class="pic"><img src="@/assets/images/seller/market-tps-005.png" alt=""></div>
				<div class="tip-main">
					<div class="name">{{$t('seller.singletreasure.title')}}</div>
					<div class="text">{{$t('seller.singletreasure.tips')}}</div>
				</div>
			</div>
		</el-card>
		<el-card class="seller-card">
			<template slot="header">{{$t('seller.coupon.title')}}</template>
			<div class="page-tools">
				<router-link class="item" :to="{ name: 'singletreasureCreate'}">
					<img class="item-pic" src="@/assets/images/seller/market-tps-012.png">
					<div class="item-main">
						<div class="name">{{$t('seller.singletreasure.title')}}</div>
						<div class="bottom">
							<el-button type="primary" size="mini">{{'+'+$t('seller.actions.createSingletreasure')}}</el-button>
						</div>
					</div>
				</router-link>
			</div>
		</el-card>
		<el-card class="seller-card card-tabs">
			<el-tabs slot="header" v-model="activeTabs" @tab-click="handleClickTabs" class="seller-tabs">
				<el-tab-pane :label="$t('seller.singletreasure.tabText1')" name="1"></el-tab-pane>
				<el-tab-pane :label="$t('seller.singletreasure.tabText2')" name="2"></el-tab-pane>
				<el-tab-pane :label="$t('seller.singletreasure.tabText3')" name="3"></el-tab-pane>
				<el-tab-pane :label="$t('seller.singletreasure.tabText4')" name="4"></el-tab-pane>
			</el-tabs>
			<act-list v-if="activeTabs === '1'" />
			<act-item v-if="activeTabs === '2'" type="2" />
			<act-item v-if="activeTabs === '3'" type="1" />
			<act-data v-if="activeTabs === '4'" />
		</el-card>
	</el-scrollbar>
</template>

<script>
import actList from './components/list'
import actItem from './components/item'
import actData from './components/data'
export default {
	components: { actList, actItem, actData },
	data() {
		return {
			activeTabs: '1'
		}
	},
	created() {
	},
	methods: {
		handleClickTabs(tab, event) {
		},
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-tip {
		display: flex;
		background-color: #FFFFFF;

		.pic {
			width: 118px;
			height: 118px;
			margin-right: 42px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.tip-main {
			flex: 1;
			width: 0;

			.name {
				color: rgb(51, 51, 51);
				font-size: 16px;
				margin-top: 10px;
			}

			.text {
				font-size: 12px;
				line-height: 18px;
				margin-top: 10px;
				margin-bottom: 20px;
			}
		}
	}

	.page-tools {
		display: flex;
		.item {
			width: 210px;
			display: flex;
			margin-right: 20px;

			.item-pic {
				width: 80px;
				height: 80px;
				object-fit: cover;
			}

			.item-main {
				padding: 2px 8px 0;

				.name {
					color: #666666;
					height: 22px;
					line-height: 22px;
					font-size: 16px;
				}

				.bottom {
					margin-top: 28px;
				}
			}
		}
	}
}
</style>
